import { styled } from '@mui/system'

const shouldForwardProp = (prop) => !['columnLayout'].includes(prop)

export const PriceWrapper = styled('div', { shouldForwardProp })`
	display: flex;
	align-items: ${({ columnLayout }) => (columnLayout ? 'flex-start' : 'center')};
	flex-wrap: wrap;
	flex-direction: ${({ columnLayout }) => (columnLayout ? 'column' : 'row')};
`

export const DefaultPrice = styled('span', { shouldForwardProp })`
	display: flex;
	align-items: center;
	flex-basis: auto;
`

export const BasePrice = styled('span', { shouldForwardProp })`
	color: var(--color-grey-50);
	text-decoration: line-through;
	flex-basis: auto;
	margin-right: ${({ columnLayout }) => (columnLayout ? 0 : '10px')};
`

export const MiddlePrice = styled('span', { shouldForwardProp })`
	color: var(--color-grey-50);
	flex-basis: auto;
	text-decoration: line-through;
	margin-right: ${({ columnLayout }) => (columnLayout ? 0 : '10px')};
`

export const SpecialPrice = styled('span', { shouldForwardProp })`
	flex-basis: auto;
`

export const DiscountPercentage = styled('span', { shouldForwardProp })`
	color: var(--color-primary);
	margin: ${({ columnLayout }) => (columnLayout ? '0' : '0 10px 0 0')};
`
export const FromLabel = styled('div', { shouldForwardProp })`
	margin: ${({ columnLayout }) => (columnLayout ? '0' : '0 5px 0 0')};
`

export const ToLabel = styled('div', { shouldForwardProp })`
	margin: ${({ columnLayout }) => (columnLayout ? '0' : '0 5px')};
`

export const OldPrice = styled('span', { shouldForwardProp })`
	color: var(--color-grey-50);
	flex-basis: auto;
	text-decoration: line-through;
	margin-right: ${({ columnLayout }) => (columnLayout ? 0 : '10px')};
`
