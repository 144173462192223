import { styled } from '@mui/system'
import Icon from '../../atoms/icon'
import { ScrollbarStyled } from '../../particles/slider/slider.styled'

const shouldForwardProp = (prop) =>
	!['action', 'controlsPosition', 'pagination', 'navigation', 'titleComponent', 'scrollbar'].includes(prop)

const Root = styled('div', { shouldForwardProp })`
	${({ theme }) => theme.breakpoints.up('sm')} {
		--swiper-navigation-size: 48px;
		${({ controlsPosition, scrollbar, navigation }) =>
			controlsPosition === 'default' && scrollbar
				? navigation
					? `--swiper-scrollbar-bottom: -30px; --swiper-scrollbar-sides-offset: calc(var(--swiper-navigation-size) + 4.8%);`
					: `--swiper-scrollbar-bottom: -30px;`
				: null}
	}

	.swiper-pagination {
		padding: 20px 16px 0;
		${({ theme }) => theme.breakpoints.up('sm')} {
			padding: ${({ controlsPosition }) =>
				controlsPosition === 'default' ? 'calc(var(--spacing-1) / 2 + 3.3%) 0 0' : '0 var(--spacing-2)'};
		}
		position: static;
		left: 0;
		transform: none;
	}

	.swiper-button-disabled {
		display: none;
	}

	.swiper-container {
		margin: 0 calc(var(--scroll-padding, var(--spacing-2)) / 10 * -1);
		padding: 0 var(--scroll-padding, var(--spacing-2));
		scroll-padding: 0 var(--scroll-padding, var(--spacing-2));
	}
`

const TopContainer = styled('div', { shouldForwardProp })`
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 0 0 var(--spacing-3) 0;
	padding: 0 var(--spacing-2);
	${({ theme }) => theme.breakpoints.up('md')} {
		margin: 0 0 var(--spacing-3);
		padding: 0 var(--spacing-2);
	}
`

const ControlsContainer = styled('div')`
	align-items: center;
	display: flex;
	justify-content: center;
	width: 100%;
	line-height: 0;
	${({ theme }) => theme.breakpoints.up('sm')} {
		justify-content: flex-end;
		width: auto;
	}
	.swiper-button-prev,
	.swiper-button-next {
		position: static;
	}
`

const NavButton = styled(Icon, { shouldForwardProp })`
	&.swiper-button-prev,
	&.swiper-button-next {
		border: 1px solid ${({ theme }) => theme.palette.grey.light};
		border-radius: 50%;
		color: ${({ theme }) => theme.palette.primary.main};
		font-size: ${({ theme }) => theme.typography.pxToRem(48)};
		padding: 13px;
	}
	${({ controlsPosition }) =>
		controlsPosition === 'top-right'
			? `
			margin-top: 0;
		`
			: null}
	${({ action, pagination }) =>
		!pagination
			? action === 'prev'
				? `
						margin-right: calc(var(--spacing-1) * 1.5);
				  `
				: `
						margin-left: calc(var(--spacing-1) * 1.5);
				  `
			: null}
`

const Pagination = styled('div', { shouldForwardProp })`
	padding: ${({ controlsPosition }) =>
		controlsPosition === 'default' ? 'calc(var(--spacing-1) / 2 + 3.3%) 0 0' : '0 var(--spacing-2)'};
	position: static;
	.swiper-pagination-bullet {
		background-color: transparent;
		height: auto;
		margin: 0 4px;
		opacity: 1;
		padding: calc(var(--spacing-1) / 2);
		width: auto;
		&:before {
			background-color: ${({ theme }) => theme.palette.grey.light};
			border-radius: 50%;
			content: '';
			display: block;
			height: 8px;
			width: 8px;
		}
		&-active {
			cursor: default;
			:before {
				background-color: ${({ theme }) => theme.palette.grey.main};
			}
		}
	}
`

const Scrollbar = styled(ScrollbarStyled, { shouldForwardProp })`
	&.swiper-scrollbar {
		position: static;
		min-width: 200px;
	}
`

export { ControlsContainer, NavButton, Pagination, Root, TopContainer, Scrollbar }
